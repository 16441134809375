import React from "react"

import Layout from "../components/Layout"
import Nav from "../components/Nav"
import SEO from "../components/seo"

const Shop = () => (
  <Layout contentWidth="narrow" id="home">
    <SEO title="Shop" />

    <h1>Coming Soon!</h1>
    <p>We aim to launch our shop and publish our first issue in the summer of 2021. More details as we have them!</p>

    <Nav className="home" variant="cta" />
  </Layout>
)

export default Shop
